import React from "react";

function ReadAndSign() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            fill="none"
            viewBox="0 0 56 56"
        >
            <circle cx="27.859" cy="27.859" r="27.859" fill="#264DC2"></circle>
            <path
                fill="#fff"
                d="M15.833 36.794l6.35-4.114h7.68c.269 0 .528.107.718.296.191.189.298.446.298.713v1.073h-5.812a.541.541 0 00-.381.157.532.532 0 00.38.912h6.278c.015 0 .029 0 .044-.002a.54.54 0 00.382-.127l6.614-5.656a1.019 1.019 0 011.599.369.99.99 0 01.08.405.99.99 0 01-.336.738l-7.543 6.744a1.563 1.563 0 01-1.044.397h-7.795a.543.543 0 00-.252.063l-4.235 2.231a.535.535 0 00-.223.723.536.536 0 00.476.284.541.541 0 00.253-.063l4.116-2.169h7.66c.653 0 1.282-.239 1.766-.672l7.543-6.744a2.085 2.085 0 00.692-1.52 2.07 2.07 0 00-.659-1.536 2.098 2.098 0 00-2.802-.06l-5.723 4.893v-.44a2.07 2.07 0 00-.614-1.469 2.106 2.106 0 00-1.48-.609h-7.84a.542.542 0 00-.295.087l-6.484 4.2a.534.534 0 00.184.971c.14.03.286.002.405-.075zM28.918 14.069c2.438 0 4.422 1.967 4.422 4.386a4.34 4.34 0 01-.756 2.456c-.222.35-1.49 2.409-1.764 3.936h-3.804c-.275-1.528-1.544-3.588-1.764-3.937a4.34 4.34 0 01-.756-2.456c0-2.418 1.984-4.385 4.422-4.385zm-4.593 7.387c.463.729 1.666 2.855 1.666 3.925a.53.53 0 00.333.494c.065.027.135.04.206.04h4.777a.541.541 0 00.539-.534c0-1.068 1.202-3.195 1.666-3.925a5.399 5.399 0 00.906-3c0-3.009-2.467-5.456-5.5-5.456-3.032 0-5.499 2.447-5.499 5.455a5.4 5.4 0 00.907 3zM31.824 27.217a.54.54 0 00-.539-.535h-4.648a.541.541 0 00-.381.157.532.532 0 00.38.913h4.649a.542.542 0 00.497-.33.53.53 0 00.041-.205h.001zM27.715 28.431a.541.541 0 00-.539.535.53.53 0 00.333.493c.065.027.135.041.206.041h2.492a.541.541 0 00.498-.33.53.53 0 00-.291-.698.544.544 0 00-.207-.041h-2.492z"
            ></path>
            <path
                fill="#fff"
                d="M27.908 21.253a.539.539 0 00.762 0l3.288-3.261a.532.532 0 00-.381-.913.541.541 0 00-.381.157l-2.907 2.884-1.202-1.193a.541.541 0 00-.92.378c0 .142.057.278.158.378l1.582 1.57z"
            ></path>
        </svg>
    );
}

export default ReadAndSign;
