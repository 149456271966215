import React, { ReactElement } from "react";
import Image from "next/image";
import SharedButton from "../sharedButton";
export default function DebtReliefMean(): ReactElement {
    return (
        <section
            className={`flex flex-col lg:flex-row  lg:gap-48 gap-8 lg:mt-36 mt-16   `}
        >
            <div className="w-[221px] h-[253px] md:w-[405px] md:min-w-[405px] md:h-[476px]  relative  mx-auto lg:mx-0   lg:mt-16 lg:left-10 ">
                <Image
                    src={"/debtreliefcorner/assets/images/whatMeanImg.jpg"}
                    alt="Image"
                    layout="fill"
                />
            </div>
            <div className="max-w-[530px] mx-auto lg:mx-0 ">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#253F91] ">
                    What Does Debt Relief Mean?
                </h2>

                <p className="mt-7  lg:text-lg  text-base lg:leading-8 ">
                    Debt relief is like discovering a secret treasure map for
                    individuals or businesses burdened by debt. It's about
                    lightening the load and finding financial solace. Debt
                    relief companies offer solutions and strategies like debt
                    consolidation, debt settlement, or debt management plans at
                    your disposal.
                </p>
                <p className="mt-4  lg:text-lg  text-base lg:leading-8 ">
                    Reputable debt relief companies and credit counseling
                    agencies do the work for you, negotiating with creditors,
                    securing lower interest rates, slashing overall debt, or
                    devising feasible repayment plans. Prepare to take control
                    of your finances and embark on a thrilling journey towards a
                    debt-free future!
                </p>
                <div className="sm:mt-12 mt-8 text-center sm:text-left">
                    <SharedButton />
                </div>
            </div>
        </section>
    );
}
