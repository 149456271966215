import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import SharedButton from "../sharedButton";
import QuickAndEasy from "./icons/quickAndEasy";
import ProfessionalsAssess from "./icons/professionalsAssess";
import ReadAndSign from "./icons/readAndSign";
import PersonalizedDebtRelief from "./icons/personalizedDebtRelief";
import AchieveFinancialFreedom from "./icons/achieveFinancialFreedom";
import Image from "next/image";
const UnlockAdvantagesList = [
    {
        icon: <QuickAndEasy />,
        description: "Just fill out our quick and easy form",
    },
    {
        icon: <ProfessionalsAssess />,
        description:
            "Let our professionals assess your debts and find the best plans and programs for you",
    },
    {
        icon: <ReadAndSign />,
        description:
            "Be sure to carefully read and sign agreements so you fully grasp the terms before committing to a debt relief plan",
    },
    {
        icon: <PersonalizedDebtRelief />,
        description: "Put your personalized debt relief strategy into action",
    },
    {
        icon: <AchieveFinancialFreedom />,
        description: "Achieve financial freedom and secure your future!",
    },
];
export default function UnlockAdvantages(): ReactElement {
    return (
        <section className={styles["mainContainer"]}>
            <div className="flex flex-col lg:flex-row lg:gap-20 gap-12   lg:pt-24 pt-12 lg:pb-48 pb-24">
                <div className="max-w-[645px] mx-auto lg:mx-0 ">
                    <h2 className="font-bold lg:text-3xl text-2xl lg:leading-10 text-[#253F91] text-center sm:text-left">
                        Unlock the Advantages of Debt Settlement
                    </h2>
                    {UnlockAdvantagesList.map((advantage, index) => (
                        <div
                            key={index}
                            className="mt-10 sm:mt-6 first-of-type:mt-10 flex flex-col sm:flex-row lg:gap-7  gap-5  items-center "
                        >
                            <span>{advantage.icon}</span>
                            <p className="lg:text-xl lg:leading-8  text-center sm:text-left">
                                {advantage.description}
                            </p>
                        </div>
                    ))}
                    <div className="lg:mt-28 mt-16 text-center sm:text-left">
                        <SharedButton />
                    </div>
                </div>
                <div className=" w-[405px] min-w-[405px] h-[476px]  relative   mt-24 hidden lg:block ">
                    <Image
                        src={"/debtreliefcorner/assets/images/unlockImage.jpg"}
                        alt="Image"
                        layout="fill"
                    />
                </div>
            </div>
        </section>
    );
}
