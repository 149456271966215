import React from "react";

function QuickAndEasy() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            fill="none"
            viewBox="0 0 56 56"
        >
            <circle cx="27.859" cy="27.859" r="27.859" fill="#264DC2"></circle>
            <path
                fill="#fff"
                d="M36.678 15.91h-4.193v-1.648a.445.445 0 00-.433-.433h-9.02a.445.445 0 00-.435.433v1.62h-4.192a.445.445 0 00-.434.433v23.968c0 .232.203.434.434.434h18.273a.445.445 0 00.433-.434v-23.94a.445.445 0 00-.433-.433zm-13.213-1.214h8.182v3.267h-8.182v-3.267zM36.244 39.85H18.839V16.778h3.73v1.619c0 .231.202.433.433.433h9.05a.445.445 0 00.433-.433v-1.62h3.73V39.85h.029z"
            ></path>
            <path
                fill="#fff"
                d="M33.758 32.708H21.325a.445.445 0 00-.433.434v3.354c0 .231.202.433.433.433H33.73a.445.445 0 00.433-.433v-3.354c.03-.231-.173-.434-.404-.434zm-.434 3.354H21.759v-2.486h11.565v2.486zM33.758 26.926H21.325a.445.445 0 00-.433.434v3.353c0 .232.202.434.433.434H33.73a.445.445 0 00.433-.434V27.36c.03-.23-.173-.433-.404-.433zm-.434 3.354H21.759v-2.487h11.565v2.487zM20.892 21.577v3.354c0 .231.202.434.433.434H33.73a.445.445 0 00.433-.434v-3.354a.445.445 0 00-.433-.434H21.325a.445.445 0 00-.433.434zm.867.434h11.565v2.486H21.759v-2.486z"
            ></path>
        </svg>
    );
}

export default QuickAndEasy;
